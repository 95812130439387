.conversation-list-item {
  align-items: center;
  cursor: pointer;
  padding: 15px 30px;
  border-bottom: 1px solid #f2f2f2;
  border-left: 5px solid transparent;
}

.conversation-list-item.active {
  border-color: #1c4b75;
  border-bottom: none;
  background: #f2f2f2;
}

.conversation-list-item:hover {
  background: #f2f2f2;
  transition: ease-in 0.5s;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 5px 0px;
}

.notification {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: #1c4b75;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
