@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
:root {
  --main-color-primary: #1C4B75;
}

* {
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.875rem !important;
  color: #464e5f !important;
}

.carrier .container {
  max-width: 1280px;
}

.login {
  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.55),
      rgba(0, 0, 0, 0.55)
    ),
    url(/images/Fundo_-_1920x1080_1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.login .form-group {
  margin-bottom: 0.5rem;
}

.login .form-group .form-label {
  font-size: 0.9rem;
}

.forgot-arrow svg {
  font-size: 1.5rem;
}

button.forgot-password {
  font-size: 0.9rem;
  color: #464e5f !important;
}

button.forgot-password:hover {
  font-weight: 500;
}

.carrier .card {
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
  border: 0;
}

.card.card-list .card-title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 5px;
  margin-right: 10px;
}

.card.card-list .card-label {
  font-weight: 500;
  margin-right: 1rem;
  margin-bottom: 0.375rem;
}

.card.card-list .card-text {
  color: #b5b5c3;
  padding: 0.375rem 0rem;
}

.card.card-list .badge,
.card.card-progress .badge {
  padding: 7px 15px;
  font-weight: 500;
  font-size: 0.75rem;
  display: inline-flex;
}

.card.card-progress {
  font-weight: 500;
}

.card.card-progress .date-progress {
  font-weight: 400;
  color: #b5b5c3;
}

.card.card-progress .progress-circle {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  line-height: 1.75rem;
  text-align: center;
  margin: 0 auto 1rem auto;
  color: #fff;
}

.log-image {
  width: 100%;
  background: #ccc;
  height: 100%;
  text-align: center;
  padding-top: 2rem;
}

.location-legends img {
  width: 1.2rem;
  margin-right: 1rem;
}

#root {
  height: 100vh;
}

.card-list .form-group {
  margin-bottom: 1.5rem;
}

.form-group .form-label {
  color: #464e5f;
  font-weight: 400;
  font-size: 0.8rem;
}

.form-group .form-control,
.form-group .react-select__control {
  border-color: #e5eaee;
  color: #b5b5c3;
}

.form-group .form-control::placeholder {
  color: #b5b5c3;
  font-size: 0.9rem;
}

.input-inline {
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.btn-inline {
  margin-top: 1.77rem;
  border-radius: 0 0.25rem 0.25rem 0 !important;
}

.fixed-alert {
  position: fixed;
  z-index: 1000;
  text-align: center;
  width: 100%;
  justify-content: center;
  display: flex;
}

.chat-header {
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: 1px solid #ddd;
}

.chat-header h6 {
  margin-bottom: 0;
  font-weight: 400;
}

.chat-header .chat-tabs {
  padding: 2rem 0 2rem 0;
  cursor: pointer;
}

.chat-header .chat-tabs:hover {
  background: #f2f2f2;
  transition: ease-in 0.3s;
}

.chat-header .chat-tabs.active {
  color: #fff;
  background: var(--main-color-primary);
  border-radius: 0.25rem 0 0 0;
}

.chat-header .chat-tabs.active h6 {
  font-weight: 500;
}

.chat-header .chat-name {
  padding: 1.5rem 1rem 1.5rem 1rem;
}

.chat-header .chat-name h6 {
  font-weight: 500;
}

.chat-list {
  max-height: 550px;
  overflow: auto;
}

.chat-list .card {
  cursor: pointer;
}

.chat-list .chat-selection {
  border-left: 5px solid;
  border-left-color: transparent;
  border-radius: 0px;
}

.chat-list .chat-selection:hover {
  border-left-color: var(--main-color-primary);
  transition: ease-in 0.3s;
}

.chat-list .card-text {
  color: #464e5f !important;
}

.chat-list .card-label {
  margin-right: 0 !important;
}

.chat-list .card-label.cod {
  color: var(--main-color-primary);
}

.chat-list::-webkit-scrollbar,
.chat-message::-webkit-scrollbar {
  width: 0.5rem;
}

.chat-list::-webkit-scrollbar-track,
.chat-message::-webkit-scrollbar-track {
  background: #f2f2f2;
}

/* Handle */
.chat-list::-webkit-scrollbar-thumb,
.chat-message::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* Handle on hover */
.chat-list::-webkit-scrollbar-thumb:hover,
.chat-message::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}

.chat-filter {
  background: #f2f2f2;
  border: 1px solid #ddd;
  border-top: none;
  border-right: none;
}

.chat-message {
  height: 550px;
  padding-top: 30px;
  padding-bottom: 80px;
  background: #f2f2f2;
  overflow: auto;
}

.chat-message .msg {
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;
}

.chat-message .msg .bubble {
  float: left;
  max-width: 340px;
  width: auto;
  height: auto;
  display: block;
  background: #fff;
  border-radius: 5px;
  position: relative;
  margin: 10px 0 3px 25px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
}

.chat-message .msg .bubble.alt {
  margin: 10px 25px 3px 0px;
  background: #fff;
  float: right;
}

.chat-message .msg .bubble .bubble-arrow:after {
  content: '';
  position: absolute;
  border-top: 15px solid #fff;
  border-left: 15px solid transparent;
  border-radius: 4px 0 0 0px;
  width: 0;
  height: 0;
}

.chat-message .msg .bubble .bubble-arrow.alt:after {
  border-top: 15px solid #c9f7f5;
  transform: scaleX(-1);
}

.chat-message .msg .bubble.follow {
  margin: 2px 0 3px 25px;
}

.chat-message .msg .bubble.altfollow {
  margin: 2px 25px 3px 0px;
  background: #c9f7f5;
  float: right;
}

.chat-message .msg .bubble .txt {
  padding: 5px 0 5px 0;
  width: 100%;
}

.chat-message .msg .bubble .txt .timestamp {
  font-size: 0.7rem;
  margin: auto;
  padding: 0 15px 0 0;
  display: table;
  float: right;
  position: relative;
  text-transform: uppercase;
  color: #464e5f;
}

.chat-message .msg .bubble.altfollow .txt .timestamp {
  color: #0bb7af;
}

.chat-message .msg .bubble .txt .message {
  font-size: 14px;
  padding: 0 20px 0 20px;
  margin: auto;
  color: #464e5f;
  display: table;
}

.chat-message .msg .bubble .txt .message.alt {
  color: #0bb7af;
}

.chat-message .msg .bubble .bubble-arrow {
  position: absolute;
  float: left;
  left: -11px;
  top: 0px;
}

.chat-message .msg .bubble .bubble-arrow.alt {
  bottom: 20px;
  left: auto;
  right: 4px;
  float: right;
}

.chat-footer {
  position: absolute;
  background: #f2f2f2;
  bottom: 0;
  width: 100%;
}

.chat-footer input {
  border-radius: 0.25rem 0 0 0.25rem;
}

.chat-footer input::placeholder,
.chat-filter input::placeholder {
  font-size: 0.8rem;
}

.chat-footer .btn {
  border-radius: 0 0.25rem 0.25rem 0;
}

@media (max-width: 576px) {
  .location-legends {
    display: flex;
  }

  .chat-list {
    max-height: 200px;
  }

  .chat-message {
    max-height: 320px;
  }

  .chat-message .msg .bubble.follow {
    margin: 5px 0 5px 25px;
  }

  .chat-message .msg .bubble.altfollow {
    margin: 5px 25px 5px 0px;
  }
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.header .nav {
    flex-direction: row;
  }
  
  .header .nav .nav-item .nav-link {
    color: #fff;
    font-weight: 400;
    font-size: 0.8rem;
  }
  
  .header .nav .nav-item .nav-link:hover {
    background: rgba(255, 255, 255, 0.15);
    transition: ease-in 0.3s;
  }
  
  .header .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background: rgba(255, 255, 255, 0.15);
    font-weight: 500;
  }
  
  @media (max-width: 576px) {
    .header .nav {
      flex-direction: column;
    }
  
    .header .nav .nav-item .nav-link {
      padding: 0.75rem 1rem;
    }
  }
  
:root {
  --main-color-primary: #1c4b75;
  --main-color-cargon: #1c4b75;
}

.container {
  max-width: 1245px;
}

.row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.text-information {
  color: white;
  padding-top: 2%;
  padding-left: 15%;
  display: flex;
}

.text-information:hover {
  cursor: pointer;
}

.icon-light {
  width: 4%;
  height: 1%;
}


.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.header-main .right-component {
  text-align: right;
}

.nav-header .dropdown-menu {
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.nav-header .dropdown-menu .dropdown-item {
  padding: 1rem;
  font-weight: 400;
}

.nav-header .dropdown-menu .dropdown-item:hover {
  background: var(--main-color-primary);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  transition: ease-in-out 0.3s;
  border-radius: 5px;
  color: #fff;
}

.nav-header.profile .dropdown-menu {
  margin-left: -108px !important;
}

.nav-header.profile .dropdown-menu .dropdown-item.active,
.dropdown-item:active {
  background: none;
  color: #212529;
}

.nav-profile a {
  color: #fff;
}

.tabs-template {
  border-color: #f2f2f2;
  border-bottom: 0;
}

.tabs-template.nav-tabs .nav-item.show .nav-link,
.tabs-template.nav-tabs .nav-link.active {
  color: #ffffff;
  background: var(--main-color-primary);
  border-color: var(--main-color-primary);
  font-weight: 500;
}

.tabs-template .nav-link {
  padding: 0.5rem 2rem;
}

.btn-align-forms {
  margin-top: 31px;
}

.btn-align-forms-xs {
  margin-top: 35px;
}

.load-label-align {
  margin-top: -6px;
}

.load-list-align {
  padding-left: 25px;
}

.load-adresses {
  border: 1px solid #f2f2f2;
  border-radius: 0px 5px 5px 5px;
}

.travel-adresses.list-maps {
  border-radius: 5px;
  margin-top: 40px;
}

.travel-label-align {
  margin-top: -6px;
}

.travel-list-align {
  padding-left: 25px;
}

.travel-adresses.list-maps {
  border-radius: 5px;
  margin-top: 40px;
}

span.name-icon img {
  width: 12px;
  height: 12px;
}

.filter {
  margin-bottom: 20px;
}

.filter .vertical {
  display: flex;
  flex-direction: row;
}

.filter .vertical.bottom {
  align-items: flex-end;
}

.filter label {
  color: #fff;
}

.modal-title {
  font-size: 20px;
  color: #212529;
}

@media (max-width: 768px) {
  .header-main {
    padding-bottom: 150px;
  }

  .header-main .left-component .text-title {
    font-size: 14px !important;
  }

  .header-main .left-component nav {
    display: none;
  }

  .header-main .right-component button {
    padding-left: 15px;
    padding-right: 15px;
  }

  .nav-header {
    margin-top: 1rem;
  }

  .nav-header .dropdown-toggle {
    padding: 1rem 1rem;
  }

  .nav-header .dropdown-menu {
    background: transparent;
    box-shadow: none;
  }

  .nav-header.profile .dropdown-menu {
    margin-left: 0px !important;
  }

  .nav-header .dropdown-menu .dropdown-item,
  .nav-header.profile .dropdown-menu .dropdown-item {
    padding: 1rem 1.5rem;
    color: #fff;
  }

  .nav-header.profile .dropdown-menu {
    margin-left: 0px;
  }

  .load-adresses.list-maps {
    margin-top: 15px;
  }

  .filter {
    margin-top: 150px;
    margin-bottom: 30px;
  }

  .filter label {
    color: #464e5f;
  }
}

.form .col-12,
.form .col-md-6,
.form .col-md-8,
.form .col {
  margin-bottom: 20px;
}

.pac-container {
  z-index: 9999;
}
.document-group {
  cursor: pointer;
}

.document-image {
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
}

.document-image .document-date {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 0.5rem;
  text-align: center;
  font-size: 0.8rem;
  padding: 0.1rem 0.5rem;
}

.document-image .document-mask {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 95%;
  height: 160px;
  text-align: center;
  transition: ease-in 1s;
}

.document-image .document-mask a {
  font-size: 2.5rem;
  color: #fff;
  margin: 5rem 0.5rem;
}

.document-image .document-mask a:hover {
  color: #0062cc;
}

@media (max-width: 576px) {
  .document-image .document-mask {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 92%;
    height: 160px;
    text-align: center;
    transition: ease-in 1s;
  }
}

.conversation-list-item {
  align-items: center;
  cursor: pointer;
  padding: 15px 30px;
  border-bottom: 1px solid #f2f2f2;
  border-left: 5px solid transparent;
}

.conversation-list-item.active {
  border-color: #1c4b75;
  border-bottom: none;
  background: #f2f2f2;
}

.conversation-list-item:hover {
  background: #f2f2f2;
  transition: ease-in 0.5s;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 5px 0px;
}

.notification {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: #1c4b75;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infinite-scroll-component {
    overflow: visible !important;
  }
  
  .css-yk16xz-control {
    border-color: #e5eaee !important;
  }
  
  .modal-content {
    border-color: transparent !important;
  }
  
  .modal-backdrop {
    background-color: rgb(33 37 41 / 50%) !important;
  }
  
  .modal-xl {
    width: 80vw !important;
    max-width: 80vw !important;
    margin-left: 180px;
  }
  
  .modal-dialog {
    max-width: 80vw !important;
  }
  
  .modal-lg {
    max-width: 50vw !important;
  }
  
  .full-height-card {
    height: calc(100% - 1rem);
  }
  
  .approval-gr {
    display: flex;
    justify-content: flex-end;
  }
  
  .backdrop-occurrences-aditional-field {
    z-index: 1050 !important;
  }
